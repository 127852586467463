define("discourse/plugins/discourse-adplugin/discourse/components/house-ads-setting", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/computed", "I18n", "@ember/template-factory"], function (_exports, _component, _object, _component2, _ajax, _ajaxError, _computed, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label for={{this.name}}>{{this.title}}</label>
  <TextField @value={{this.adValue}} @classNames="house-ads-text-input" />
  <div class="setting-controls">
    {{#if this.changed}}
      <DButton class="ok" @action={{action "save"}} @icon="check" />
      <DButton class="cancel" @action={{action "cancel"}} @icon="xmark" />
    {{/if}}
  </div>
  <p class="help">{{this.help}}</p>
  */
  {
    "id": "Z9ypDcbY",
    "block": "[[[10,\"label\"],[15,\"for\",[30,0,[\"name\"]]],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n\"],[8,[39,1],null,[[\"@value\",\"@classNames\"],[[30,0,[\"adValue\"]],\"house-ads-text-input\"]],null],[1,\"\\n\"],[10,0],[14,0,\"setting-controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"changed\"]],[[[1,\"    \"],[8,[39,4],[[24,0,\"ok\"]],[[\"@action\",\"@icon\"],[[28,[37,5],[[30,0],\"save\"],null],\"check\"]],null],[1,\"\\n    \"],[8,[39,4],[[24,0,\"cancel\"]],[[\"@action\",\"@icon\"],[[28,[37,5],[[30,0],\"cancel\"],null],\"xmark\"]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,2],[14,0,\"help\"],[12],[1,[30,0,[\"help\"]]],[13]],[],false,[\"label\",\"text-field\",\"div\",\"if\",\"d-button\",\"action\",\"p\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/components/house-ads-setting.hbs",
    "isStrictMode": false
  });
  const HouseAdsSetting = dt7948.c(class HouseAdsSetting extends _component.default {
    adValue = "";
    saving = false;
    savingStatus = "";
    static #_ = (() => dt7948.g(this.prototype, "title", [(0, _computed.i18n)("name", "admin.adplugin.house_ads.%@.title")]))();
    #title = (() => (dt7948.i(this, "title"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "help", [(0, _computed.i18n)("name", "admin.adplugin.house_ads.%@.description")]))();
    #help = (() => (dt7948.i(this, "help"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "changed", [(0, _computed.propertyNotEqual)("adValue", "value")]))();
    #changed = (() => (dt7948.i(this, "changed"), void 0))();
    init() {
      super.init(...arguments);
      this.set("adValue", this.get("value"));
    }
    save() {
      if (!this.get("saving")) {
        this.setProperties({
          saving: true,
          savingStatus: _I18n.default.t("saving")
        });
        (0, _ajax.ajax)(`/admin/plugins/pluginad/house_settings/${this.get("name")}.json`, {
          type: "PUT",
          data: {
            value: this.get("adValue")
          }
        }).then(() => {
          const adSettings = this.get("adSettings");
          adSettings.set(this.get("name"), this.get("adValue"));
          this.setProperties({
            value: this.get("adValue"),
            savingStatus: _I18n.default.t("saved")
          });
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.setProperties({
            saving: false,
            savingStatus: ""
          });
        });
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    cancel() {
      this.set("adValue", this.get("value"));
    }
    static #_5 = (() => dt7948.n(this.prototype, "cancel", [_object.action]))();
  }, [(0, _component2.classNames)("house-ads-setting")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HouseAdsSetting);
});
define("discourse/plugins/discourse-adplugin/discourse/components/modal/preview", ["exports", "@ember/template", "discourse/components/d-modal", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _template, _dModal, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Preview = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DModal
      @closeModal={{@closeModal}}
      @title={{i18n "admin.adplugin.house_ads.preview"}}
    >
      <:body>
        <div class="house-ad-preview">
          {{htmlSafe @model.html}}
        </div>
      </:body>
    </DModal>
  
  */
  {
    "id": "rprbfKE8",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@closeModal\",\"@title\"],[[30,1],[28,[32,1],[\"admin.adplugin.house_ads.preview\"],null]]],[[\"body\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"house-ad-preview\"],[12],[1,\"\\n        \"],[1,[28,[32,2],[[30,2,[\"html\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@closeModal\",\"@model\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-adplugin/discourse/components/modal/preview.js",
    "scope": () => [_dModal.default, _i18n.default, _template.htmlSafe],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "preview:Preview"));
  var _default = _exports.default = Preview;
});
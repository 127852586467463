define("discourse/plugins/discourse-adplugin/discourse/components/house-ad", ["exports", "@ember/component", "@ember/utils", "@ember-decorators/component", "discourse-common/utils/decorators", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "@ember/template-factory"], function (_exports, _component, _utils, _component2, _decorators, _adComponent, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showAd}}
    {{html-safe this.adHtml}}
  {{/if}}
  */
  {
    "id": "5YhP8xZT",
    "block": "[[[41,[30,0,[\"showAd\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"adHtml\"]]],null]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/components/house-ad.hbs",
    "isStrictMode": false
  });
  const adIndex = {
    topic_list_top: null,
    topic_above_post_stream: null,
    topic_above_suggested: null,
    post_bottom: null,
    topic_list_between: null
  };
  const HouseAd = dt7948.c(class HouseAd extends _adComponent.default {
    adHtml = "";
    colspanAttribute() {
      return this.tagName === "td" ? "5" : null;
    }
    static #_ = (() => dt7948.n(this.prototype, "colspanAttribute", [_decorators.default]))();
    adUnitClass(placement, showAd) {
      return showAd ? `house-${placement}` : "";
    }
    static #_2 = (() => dt7948.n(this.prototype, "adUnitClass", [(0, _decorators.default)("placement", "showAd")]))();
    showAd(showToGroups, showAfterPost, showAfterTopicListItem, showOnCurrentPage) {
      return showToGroups && (showAfterPost || showAfterTopicListItem) && showOnCurrentPage;
    }
    static #_3 = (() => dt7948.n(this.prototype, "showAd", [(0, _decorators.default)("showToGroups", "showAfterPost", "showAfterTopicListItem", "showOnCurrentPage")]))();
    showAfterPost(postNumber, placement) {
      if (!postNumber && placement !== "topic-list-between") {
        return true;
      }
      return this.isNthPost(parseInt(this.site.get("house_creatives.settings.after_nth_post"), 10));
    }
    static #_4 = (() => dt7948.n(this.prototype, "showAfterPost", [(0, _decorators.default)("postNumber", "placement")]))();
    showAfterTopicListItem(placement) {
      if (placement !== "topic-list-between") {
        return true;
      }
      return this.isNthTopicListItem(parseInt(this.site.get("house_creatives.settings.after_nth_topic"), 10));
    }
    static #_5 = (() => dt7948.n(this.prototype, "showAfterTopicListItem", [(0, _decorators.default)("placement")]))();
    chooseAdHtml() {
      const houseAds = this.site.get("house_creatives"),
        placement = this.get("placement").replace(/-/g, "_"),
        adNames = this.adsNamesForSlot(placement);

      // filter out ads that should not be shown on the current page
      const filteredAds = adNames.filter(adName => {
        const ad = houseAds.creatives[adName];
        return ad && (!ad.category_ids?.length || ad.category_ids.includes(this.currentCategoryId));
      });
      if (filteredAds.length > 0) {
        if (!adIndex[placement]) {
          adIndex[placement] = 0;
        }
        let ad = houseAds.creatives[filteredAds[adIndex[placement]]] || "";
        adIndex[placement] = (adIndex[placement] + 1) % filteredAds.length;
        return ad.html;
      }
    }
    adsNamesForSlot(placement) {
      const houseAds = this.site.get("house_creatives");
      if (!houseAds || !houseAds.settings) {
        return [];
      }
      const adsForSlot = houseAds.settings[placement];
      if (Object.keys(houseAds.creatives).length > 0 && !(0, _utils.isBlank)(adsForSlot)) {
        return adsForSlot.split("|");
      } else {
        return [];
      }
    }
    refreshAd() {
      this.set("adHtml", this.chooseAdHtml());
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      if (!this.get("showAd")) {
        return;
      }
      if (adIndex.topic_list_top === null) {
        // start at a random spot in the ad inventory
        const houseAds = this.site.get("house_creatives");
        Object.keys(adIndex).forEach(placement => {
          const adNames = this.adsNamesForSlot(placement);
          if (adNames.length === 0) {
            return;
          }
          // filter out ads that should not be shown on the current page
          const filteredAds = adNames.filter(adName => {
            const ad = houseAds.creatives[adName];
            return ad && (!ad.category_ids?.length || ad.category_ids.includes(this.currentCategoryId));
          });
          adIndex[placement] = Math.floor(Math.random() * filteredAds.length);
        });
      }
      this.refreshAd();
    }
  }, [(0, _component2.attributeBindings)("colspanAttribute:colspan"), (0, _component2.classNameBindings)("adUnitClass"), (0, _component2.classNames)("house-creative")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HouseAd);
});
define("discourse/plugins/discourse-adplugin/discourse/components/amazon-product-links", ["exports", "@ember/component", "@ember/object/computed", "@ember/template", "@ember-decorators/component", "discourse-common/utils/decorators", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "@ember/template-factory"], function (_exports, _component, _computed, _template, _component2, _decorators, _adComponent, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showAd}}
    {{#if this.site.mobileView}}
      <div
        class="amazon-product-links-label"
        style={{this.adTitleStyleMobile}}
      ><h2>{{i18n "adplugin.advertisement_label"}}</h2></div>
      <iframe
        style={{this.adWrapperStyleMobile}}
        marginwidth="0"
        marginheight="0"
        scrolling="no"
        frameborder="0"
        src={{this.userInputMobile}}
        title={{i18n "adplugin.advertisement_label"}}
      >
      </iframe>
    {{else}}
      <div class="amazon-product-links-label"><h2>{{i18n
            "adplugin.advertisement_label"
          }}</h2></div>
      <div class="container" align="center">
        <iframe
          style={{this.adWrapperStyle}}
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          frameborder="0"
          src={{this.userInput}}
          title={{i18n "adplugin.advertisement_label"}}
        >
        </iframe>
      </div>
    {{/if}}
  {{/if}}
  */
  {
    "id": "ZXlVr5Zu",
    "block": "[[[41,[30,0,[\"showAd\"]],[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"    \"],[10,0],[14,0,\"amazon-product-links-label\"],[15,5,[30,0,[\"adTitleStyleMobile\"]]],[12],[10,\"h2\"],[12],[1,[28,[35,3],[\"adplugin.advertisement_label\"],null]],[13],[13],[1,\"\\n    \"],[10,\"iframe\"],[15,5,[30,0,[\"adWrapperStyleMobile\"]]],[14,\"marginwidth\",\"0\"],[14,\"marginheight\",\"0\"],[14,\"scrolling\",\"no\"],[14,\"frameborder\",\"0\"],[15,\"src\",[30,0,[\"userInputMobile\"]]],[15,\"title\",[28,[37,3],[\"adplugin.advertisement_label\"],null]],[12],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"amazon-product-links-label\"],[12],[10,\"h2\"],[12],[1,[28,[35,3],[\"adplugin.advertisement_label\"],null]],[13],[13],[1,\"\\n    \"],[10,0],[14,0,\"container\"],[14,\"align\",\"center\"],[12],[1,\"\\n      \"],[10,\"iframe\"],[15,5,[30,0,[\"adWrapperStyle\"]]],[14,\"marginwidth\",\"0\"],[14,\"marginheight\",\"0\"],[14,\"scrolling\",\"no\"],[14,\"frameborder\",\"0\"],[15,\"src\",[30,0,[\"userInput\"]]],[15,\"title\",[28,[37,3],[\"adplugin.advertisement_label\"],null]],[12],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"if\",\"div\",\"h2\",\"i18n\",\"iframe\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/components/amazon-product-links.hbs",
    "isStrictMode": false
  });
  const AmazonProductLinks = dt7948.c(class AmazonProductLinks extends _adComponent.default {
    static #_ = (() => dt7948.g(this.prototype, "showAd", [(0, _computed.and)("showAmazonAds", "showToGroups", "showAfterPost", "showOnCurrentPage")]))();
    #showAd = (() => (dt7948.i(this, "showAd"), void 0))();
    init() {
      const data = {
        "topic-list-top": {},
        "topic-above-post-stream": {},
        "topic-above-suggested": {},
        "post-bottom": {}
      };
      const mobileView = this.site.get("mobileView");
      const placement = this.get("placement");
      if (!mobileView && this.siteSettings.amazon_topic_list_top_src_code) {
        data["topic-list-top"]["user_input"] = this.siteSettings.amazon_topic_list_top_src_code;
        data["topic-list-top"]["amazon_width"] = parseInt(this.siteSettings.amazon_topic_list_top_ad_width_code, 10);
        data["topic-list-top"]["amazon_height"] = parseInt(this.siteSettings.amazon_topic_list_top_ad_height_code, 10);
      }
      if (mobileView && this.siteSettings.amazon_mobile_topic_list_top_src_code) {
        data["topic-list-top"]["user_input_mobile"] = this.siteSettings.amazon_mobile_topic_list_top_src_code;
        data["topic-list-top"]["mobile_amazon_width"] = parseInt(this.siteSettings.amazon_mobile_topic_list_top_ad_width_code, 10);
        data["topic-list-top"]["mobile_amazon_height"] = parseInt(this.siteSettings.amazon_mobile_topic_list_top_ad_height_code, 10);
      }
      if (!mobileView && this.siteSettings.amazon_topic_above_post_stream_src_code) {
        data["topic-above-post-stream"]["user_input"] = this.siteSettings.amazon_topic_above_post_stream_src_code;
        data["topic-above-post-stream"]["amazon_width"] = parseInt(this.siteSettings.amazon_topic_above_post_stream_ad_width_code, 10);
        data["topic-above-post-stream"]["amazon_height"] = parseInt(this.siteSettings.amazon_topic_above_post_stream_ad_height_code, 10);
      }
      if (mobileView && this.siteSettings.amazon_mobile_topic_above_post_stream_src_code) {
        data["topic-above-post-stream"]["user_input_mobile"] = this.siteSettings.amazon_mobile_topic_above_post_stream_src_code;
        data["topic-above-post-stream"]["mobile_amazon_width"] = parseInt(this.siteSettings.amazon_mobile_topic_above_post_stream_ad_width_code, 10);
        data["topic-above-post-stream"]["mobile_amazon_height"] = parseInt(this.siteSettings.amazon_mobile_topic_above_post_stream_ad_height_code, 10);
      }
      if (!mobileView && this.siteSettings.amazon_topic_above_suggested_src_code) {
        data["topic-above-suggested"]["user_input"] = this.siteSettings.amazon_topic_above_suggested_src_code;
        data["topic-above-suggested"]["amazon_width"] = parseInt(this.siteSettings.amazon_topic_above_suggested_ad_width_code, 10);
        data["topic-above-suggested"]["amazon_height"] = parseInt(this.siteSettings.amazon_topic_above_suggested_ad_height_code, 10);
      }
      if (mobileView && this.siteSettings.amazon_mobile_topic_above_suggested_src_code) {
        data["topic-above-suggested"]["user_input_mobile"] = this.siteSettings.amazon_mobile_topic_above_suggested_src_code;
        data["topic-above-suggested"]["mobile_amazon_width"] = parseInt(this.siteSettings.amazon_mobile_topic_above_suggested_ad_width_code, 10);
        data["topic-above-suggested"]["mobile_amazon_height"] = parseInt(this.siteSettings.amazon_mobile_topic_above_suggested_ad_height_code, 10);
      }
      if (!mobileView && this.siteSettings.amazon_post_bottom_src_code) {
        data["post-bottom"]["user_input"] = this.siteSettings.amazon_post_bottom_src_code;
        data["post-bottom"]["amazon_width"] = parseInt(this.siteSettings.amazon_post_bottom_ad_width_code, 10);
        data["post-bottom"]["amazon_height"] = parseInt(this.siteSettings.amazon_post_bottom_ad_height_code, 10);
      }
      if (mobileView && this.siteSettings.amazon_mobile_post_bottom_src_code) {
        data["post-bottom"]["user_input_mobile"] = this.siteSettings.amazon_mobile_post_bottom_src_code;
        data["post-bottom"]["mobile_amazon_width"] = parseInt(this.siteSettings.amazon_mobile_post_bottom_ad_width_code, 10);
        data["post-bottom"]["mobile_amazon_height"] = parseInt(this.siteSettings.amazon_mobile_post_bottom_ad_height_code, 10);
      }
      this.set("user_input", data[placement]["user_input"]);
      this.set("amazon_width", data[placement]["amazon_width"]);
      this.set("amazon_height", data[placement]["amazon_height"]);
      this.set("user_input_mobile", data[placement]["user_input_mobile"]);
      this.set("mobile_amazon_height", data[placement]["mobile_amazon_height"]);
      this.set("mobile_amazon_width", data[placement]["mobile_amazon_width"]);
      super.init();
    }
    adWrapperStyle(w, h) {
      return (0, _template.htmlSafe)(`width: ${w}px; height: ${h}px;`);
    }
    static #_2 = (() => dt7948.n(this.prototype, "adWrapperStyle", [(0, _decorators.default)("amazon_width", "amazon_height")]))();
    adWrapperStyleMobile(w, h) {
      return (0, _template.htmlSafe)(`width: ${w}px; height: ${h}px;`);
    }
    static #_3 = (() => dt7948.n(this.prototype, "adWrapperStyleMobile", [(0, _decorators.default)("mobile_amazon_width", "mobile_amazon_height")]))();
    adTitleStyleMobile(w) {
      return (0, _template.htmlSafe)(`width: ${w}px;`);
    }
    static #_4 = (() => dt7948.n(this.prototype, "adTitleStyleMobile", [(0, _decorators.default)("mobile_amazon_width")]))();
    userInput(userInput) {
      return (0, _template.htmlSafe)(`${userInput}`);
    }
    static #_5 = (() => dt7948.n(this.prototype, "userInput", [(0, _decorators.default)("user_input")]))();
    userInputMobile(userInput) {
      return (0, _template.htmlSafe)(`${userInput}`);
    }
    static #_6 = (() => dt7948.n(this.prototype, "userInputMobile", [(0, _decorators.default)("user_input_mobile")]))();
    showAmazonAds() {
      if (!this.currentUser) {
        return true;
      }
      return this.currentUser.show_amazon_ads;
    }
    static #_7 = (() => dt7948.n(this.prototype, "showAmazonAds", [_decorators.default]))();
    showAfterPost(postNumber) {
      if (!postNumber) {
        return true;
      }
      return this.isNthPost(parseInt(this.siteSettings.amazon_nth_post_code, 10));
    }
    static #_8 = (() => dt7948.n(this.prototype, "showAfterPost", [(0, _decorators.default)("postNumber")]))();
  }, [(0, _component2.classNames)("amazon-product-links")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmazonProductLinks);
});